<template>
  <div class="article-list__wrapper">
    <div
      v-for="(article, index) in articles"
      :key="`${article[idField]}${index}`"
      class="article-list__item-wrapper"
    >
      <slot :article="article" :position="index" />
      <slot name="disqus" :position="index" />
      <slot name="banner" :position="index" />
      <slot name="video" :position="index" />
    </div>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'AArticleList',
  props: {
    articles: propValidator([PROP_TYPES.ARRAY]),
    idField: propValidator([PROP_TYPES.STRING])
  }
}
</script>

<style lang="scss">
/* TODO animation requirements are to be discussed with the client
//.list-enter-active,
//.list-leave-active {
//  transition: all 1s;
//}
//
//.list-enter,
//.list-leave-to {
//  opacity: 0;
//} */
</style>
