<template>
  <span class="dots__wrapper" :style="dynamicStyles">
    <component
      :is="tag || 'span'"
      class="dots__visible"
      :style="visibleDynamicStyle"
    >
      <a-link v-if="linkProps" v-bind="linkProps">{{ printedText }}</a-link>
      <template v-else>
        {{ printedText }}
      </template>
    </component>

    <span
      v-if="!isTextVisible"
      ref="actualContent"
      class="dots__actual-content"
    >
      {{ text }}
    </span>

    <span v-if="isTextListVisible" class="dots__calculate">
      <span
        v-for="(textOption, index) in textList"
        :key="index"
        ref="content"
        class="display-block"
      >
        {{ textOption }}
      </span>
    </span>
  </span>
</template>

<script>
import { debounce } from 'debounce'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

/**
 * Be aware that we replaced all DIV tags with SPAN to avoid using div inside p
 * Where: ATerm component uses ADots and is used in article body.
 * If div is rendered inside p, render errors/warnings occur.
 * Bad template: <p>Hello<div>test</div></p>
 */

export default {
  name: 'ADots',
  mixins: [hydrationHelpers],
  props: {
    text: propValidator([PROP_TYPES.STRING]),
    rowCount: propValidator([PROP_TYPES.NUMBER], false, 0),
    rowHeight: propValidator([PROP_TYPES.NUMBER], false),
    tag: propValidator([PROP_TYPES.STRING], false, 'span'),
    fixedHeight: propValidator([PROP_TYPES.BOOLEAN], false, false),
    linkProps: propValidator([PROP_TYPES.OBJECT], false)
  },
  consts: { bottomPadding: 1 },
  data() {
    return {
      formattedText: this.text,
      isTextVisible: false,
      textList: [],
      heightList: [],
      textIndex: 0,
      isTextListVisible: false,
      checkIfDotsNeededDebounced: debounce(function() {
        this.checkIfDotsNeeded()
      }, 200)
    }
  },
  computed: {
    visibleDynamicStyle() {
      return {
        maxHeight: `${this.limitedHeight +
          this.$options.consts.bottomPadding}px`
      }
    },
    limitedHeight() {
      return this.rowCount * this.rowHeight
    },
    printedText() {
      return this.isTextVisible
        ? this.textList[this.textIndex] || this.text
        : this.text
    },
    maxTopicHeight() {
      return this.rowCount * this.rowHeight
    },
    dynamicStyles() {
      if (!this.fixedHeight) return {}

      return {
        height: `${this.maxTopicHeight}px`
      }
    }
  },
  watch: {
    $_hydrationHelpers_windowWidth: {
      handler(newVal, oldVal) {
        if (!oldVal) return

        this.isTextVisible = false
        this.$nextTick(this.checkIfDotsNeededDebounced)
      }
    }
  },
  methods: {
    checkIfDotsNeeded() {
      this.textList = []
      const actualContent = this.$refs.actualContent
      if (!actualContent) return

      const actualContentHeight = actualContent.clientHeight
      if (actualContentHeight > this.limitedHeight) {
        this.generateTextList()
      } else {
        this.isTextVisible = true
      }
    },
    generateTextList() {
      for (let i = 1; i < this.text.length; i++) {
        const currentText =
          this.text
            .split('')
            .splice(0, this.text.length - i)
            .join('') + '...'
        this.textList.push(currentText)
      }
      this.isTextListVisible = true
      this.$nextTick(this.generateContentHeightList)
    },
    generateContentHeightList() {
      if (!this.$refs.content) return

      this.heightList = this.$refs.content.map(
        textContent => textContent.clientHeight
      )
      this.textIndex = this.heightList.findIndex(
        height => height <= this.limitedHeight
      )
      this.isTextVisible = true
      this.isTextListVisible = false
      this.$emit('complete')
    }
  },
  mounted() {
    this.checkIfDotsNeeded()
  }
}
</script>

<style lang="scss" scoped>
.dots__wrapper {
  position: relative;
  display: block;
  overflow: hidden;

  .dots__visible {
    display: block;
    overflow: hidden;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding-bottom: 2px;
  }

  .dots__calculate,
  .dots__actual-content {
    display: block;
    visibility: hidden;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
</style>
