function categoryName() {
  return {
    slotName: 'category',
    getData: () => ({})
  }
}

function articleSlot({ showMedia, topicLineCount, articleIndex, fixedHeight }) {
  if (articleIndex == null) return {}

  return {
    slotName: 'articleSlot',
    getData: ({ data }) => ({
      article: data?.Articles?.[articleIndex],
      showMedia,
      topicLineCount,
      fixedHeight
    })
  }
}

/**
 * Category Name
 */
export function c() {
  return categoryName()
}

/**
 * Article without Featured Media
 */
export function a(articleIndex, topicLineCount, fixedHeight) {
  return articleSlot({
    showMedia: false,
    topicLineCount,
    articleIndex,
    fixedHeight
  })
}

/**
 * Article with Featured Media
 */
export function am(articleIndex, topicLineCount, fixedHeight) {
  return articleSlot({
    showMedia: true,
    topicLineCount,
    articleIndex,
    fixedHeight
  })
}

/**
 * Banner
 */
export function b() {
  return {
    slotName: 'banner',
    getData: () => ({})
  }
}
